import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 12 12';
const id = 'help_12';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" id="help_12"><path fill-rule="evenodd" clip-rule="evenodd" d="M6 12A6 6 0 106 0a6 6 0 000 12zm.644-5.005c-.08.36-.286.57-.662.57-.45 0-.715-.312-.715-.753v-.075c0-.633.323-1.052.948-1.423.684-.414.89-.682.89-1.176 0-.527-.402-.892-.985-.892-.52 0-.869.258-1.038.736-.133.344-.371.494-.694.494-.419 0-.678-.263-.678-.671 0-.231.053-.435.159-.64C4.208 2.458 5.055 2 6.194 2c1.504 0 2.516.838 2.516 2.09 0 .81-.387 1.364-1.134 1.81-.704.413-.863.644-.932 1.095zm.217 2.162a.85.85 0 01-.868.843.85.85 0 01-.864-.843.85.85 0 01.864-.844.85.85 0 01.868.844z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon12HelpProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon12Help: FC<Icon12HelpProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 12,
    height: !isNaN(props.height) ? +props.height : 12,
  }));
};

(Icon12Help as any).mountIcon = mountIcon;

export default Icon12Help;
