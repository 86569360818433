import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'crown_outline_28';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 28 28" id="crown_outline_28"><path fill="currentColor" fill-rule="evenodd" d="M16.734 5.77c-1.064-2.352-4.404-2.352-5.468 0L9.535 9.6 6.481 7.915c-2.323-1.282-5.045.86-4.344 3.42L4 18.133v1.704c0 .528 0 .982.03 1.357.033.395.104.789.297 1.167a3 3 0 001.311 1.311c.378.193.772.264 1.167.296.375.031.83.031 1.357.031h11.676c.528 0 .982 0 1.357-.03.395-.033.789-.104 1.167-.297a3 3 0 001.311-1.311c.193-.378.264-.772.296-1.167.031-.375.031-.83.031-1.356v-1.704l1.863-6.802c.7-2.559-2.02-4.701-4.343-3.419l-3.055 1.687-1.731-3.83zM22 19H6v.8c0 .577 0 .949.024 1.232.022.272.06.372.085.422a1 1 0 00.437.437c.05.025.15.063.422.085C7.25 22 7.623 22 8.2 22h11.6c.577 0 .949 0 1.232-.024.272-.022.372-.06.422-.085a1 1 0 00.437-.437c.025-.05.063-.15.085-.422C22 20.75 22 20.377 22 19.8V19zM13.089 6.594c.354-.784 1.468-.784 1.822 0l2.178 4.818a1 1 0 001.394.463l4.003-2.21c.774-.427 1.682.287 1.448 1.14L22.237 17H5.763l-1.697-6.195c-.234-.853.674-1.567 1.448-1.14l4.003 2.21a1 1 0 001.394-.463l2.178-4.818z" clip-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28CrownOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28CrownOutline: FC<Icon28CrownOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28CrownOutline as any).mountIcon = mountIcon;

export default Icon28CrownOutline;
